var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "myStrategiesRef",
      staticClass: "strategies",
      attrs: { color: "#ffffff" }
    },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _vm.$currentUser.userMode === "A"
                    ? _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500",
                                "min-width": "90"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.localAdminSelectedTabName
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3317983016
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: {
                                        color: "primary",
                                        mandatory: "",
                                        "aria-label": _vm.$t(
                                          "strategyTypeLabel"
                                        )
                                      },
                                      model: {
                                        value: _vm.localAdminSelectedTab,
                                        callback: function($$v) {
                                          _vm.localAdminSelectedTab = $$v
                                        },
                                        expression: "localAdminSelectedTab"
                                      }
                                    },
                                    [
                                      _c("v-list-item", [
                                        _vm._v(_vm._s(_vm.$t("schoolLabel")))
                                      ]),
                                      _c("v-list-item", [
                                        _vm._v(_vm._s(_vm.$t("districtLabel")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: { "mt-5": _vm.$vuetify.breakpoint.mdAndUp },
                  attrs: { flat: "" }
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      headers: _vm.localSortByKeys,
                      items: _vm.filteredLocalStrategies,
                      "item-key": "dbId",
                      search: _vm.searchText,
                      "show-select": _vm.editMode,
                      height: _vm.tableHeight,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      loading: _vm.localListLoading,
                      "loading-text": _vm.$t("listLoadingMsg"),
                      "mobile-breakpoint": _vm.mobileBreakpoint
                    },
                    on: { "click:row": _vm.editSelectedStrategy },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editSelectedStrategy(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.editSelectedStrategy(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.strategyTitle
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.localSortByKeys[0].text
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editStrategyId &&
                                      (_vm.localAdminSelectedTab === 0 ||
                                        _vm.$currentUser.userType === "D")
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  "single-line": "",
                                                  "append-icon": "fa fa-check"
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.saveStrategyId(
                                                      item
                                                    )
                                                  },
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    return _vm.saveStrategyId(
                                                      item
                                                    )
                                                  },
                                                  blur:
                                                    _vm.strategyIdEditableBlur
                                                },
                                                model: {
                                                  value: item.strategyId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "strategyId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.strategyId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleStrategyIdEditable(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.strategyId))
                                              ]),
                                              _vm.localAdminSelectedTab === 0 ||
                                              _vm.$currentUser.userType === "D"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 hover-edit-icon",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-pencil"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.localSortByKeys[1].text
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.editStrategyTitle &&
                                      (_vm.localAdminSelectedTab === 0 ||
                                        _vm.$currentUser.userType === "D")
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  autofocus: "",
                                                  "single-line": "",
                                                  "append-icon": "fa fa-check"
                                                },
                                                on: {
                                                  keyup: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.saveStrategyTitle(
                                                      item
                                                    )
                                                  },
                                                  "click:append": function(
                                                    $event
                                                  ) {
                                                    return _vm.saveStrategyTitle(
                                                      item
                                                    )
                                                  },
                                                  blur:
                                                    _vm.strategyTitleEditableBlur
                                                },
                                                model: {
                                                  value: item.strategyTitle,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "strategyTitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.strategyTitle"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              class: _vm.mobileCellClass,
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.toggleStrategyTitleEditable(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.strategyTitle)
                                                )
                                              ]),
                                              _vm.localAdminSelectedTab === 0 ||
                                              _vm.$currentUser.userType === "D"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hover-edit-icon",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "pl-1",
                                                          attrs: {
                                                            "x-small": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fal fa-pencil"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText
                                        }
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.localSortByKeys[2].text
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("div", {
                                          staticClass: "remove-margin",
                                          class: _vm.mobileCellClass,
                                          domProps: {
                                            innerHTML: _vm._s(item.strategyDesc)
                                          }
                                        })
                                      ]
                                    ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.localSortByKeys[3].text
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [
                                          _vm.localAdminSelectedTab === 0 ||
                                          _vm.$currentUser.userType === "D"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-primary",
                                                  attrs: {
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "primary"
                                                        : "transparent",
                                                    text: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editSelectedStrategy(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("editLabel")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.localAdminSelectedTab === 0 ||
                                          _vm.$currentUser.userType === "D"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-error",
                                                  attrs: {
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "error"
                                                        : "transparent",
                                                    small: "",
                                                    text: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteSelectedStrategy(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("deleteLabel")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "strategiesConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }