































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import WebUtils from '@/utils/web-utils';
import MyStrategiesMixin from '@/mixins/mystrategies-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';

const mystrategies = namespace('mystrategies');
const settings = namespace('settings');

@Component({
  mixins: [MyStrategiesMixin, TableResizeMixin, PageLifeCycleMixin]
})
export default class MyStrategy extends Vue {
  localRefreshKey = CommonUtils.generateUUID();
  localStrategies: Array<any> = [];
  localIsEditingStrategies = false;
  localAdminSelectedTab = 0;
  localSlideGroupModel = null;
  localSortByKeys = [
    { text: this.$t('idLabel'), value: 'strategyId', width: this.tableWidths.shortText, sortable: true },
    { text: this.$t('titleLabel'), value: 'strategyTitle', width: this.tableWidths.longText, sortable: true },
    { text: this.$t('descriptionLabel'), value: 'strategyDesc', sortable: true },
    { width: this.tableWidths.action2, value: 'actions', sortable: false }
  ];

  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  tableHeight!: string;

  $refs!: {
    strategiesConfirm: Confirm
  }

  @mystrategies.Action
  updateStrategy!: (params?: any) => Promise<any>;

  @mystrategies.Action
  loadStrategies!: () => Promise<any>;

  @mystrategies.Action
  deleteStrategy!: (params: any) => Promise<any>;

  @mystrategies.Getter
  getAllStrategies!: any;

  @mystrategies.Getter
  getListLoading!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  get filteredLocalStrategies(): any {
    console.log(this.localStrategies);
    if (this.$currentUser.isAdmin && this.localAdminSelectedTab === 0) {
      return this.localStrategies.filter((ll: any) => ll.strategyGroupType === 'S');
    } else if (this.$currentUser.isAdmin && this.localAdminSelectedTab === 1) {
      return this.localStrategies.filter((ll: any) => ll.strategyGroupType === 'D');
    } else {
      return this.localStrategies;
    }
  }

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('mystrategies/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get tableWidths() {
    return tableWidths;
  }

  get localAdminSelectedTabName() {
    return (this.localAdminSelectedTab ? this.$t('districtLabel') : this.$t('schoolLabel'));
  }

  private initialize(initialLoad:boolean) {
    initialLoad ? CommonUtils.showLoading() : this.localListLoading = true;
    this.loadStrategies().then(() => {
      this.localStrategies = this.getAllStrategies;
    }).finally(() => {
      this.localRefreshKey = CommonUtils.generateUUID();
      CommonUtils.hideLoading();
      this.localListLoading = false;
    });
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  toggleStrategyIdEditable(item: any) {
    const strategies = ld.cloneDeep(this.localStrategies);
    strategies.forEach(s => {
      s.editStrategyId = false;
    });
    const strategy = strategies.find(s => s.dbId === item.dbId);
    strategy.editStrategyId = !strategy.editStrategyId;
    this.localStrategies = strategies;
  }

  strategyIdEditableBlur() {
    const strategies = ld.cloneDeep(this.localStrategies);
    strategies.forEach(s => {
      s.editStrategyId = false;
    });
    this.localStrategies = strategies;
  }

  toggleStrategyTitleEditable(item: any) {
    const strategies = ld.cloneDeep(this.localStrategies);
    strategies.forEach(s => {
      s.editStrategyTitle = false;
    });
    const strategy = strategies.find(s => s.dbId === item.dbId);
    strategy.editStrategyTitle = !strategy.editStrategyTitle;
    this.localStrategies = strategies;
  }

  strategyTitleEditableBlur() {
    const strategies = ld.cloneDeep(this.localStrategies);
    strategies.forEach(s => {
      s.editStrategyTitle = false;
    });
    this.localStrategies = strategies;
  }

  addNewStrategy() {
    this.$eventBus.$emit('openSubPage', {
      type: 'mystrategies',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'add'
      }
    });
    this.localIsEditingStrategies = true;
  }

  editSelectedStrategy(item: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'mystrategies',
      width: 600,
      modal: this.$currentUser.defaultEditorMode === 'modal',
      input: {
        action: 'edit',
        strategyObject: item
      }
    });
    this.localIsEditingStrategies = true;
  }

  deleteSelectedStrategy(strategy: any) {
    const that = this;
    this.$refs.strategiesConfirm.confirm({
      title: this.$t('strategyLabel'),
      text: this.$t('deleteStrategyLabel'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        const ids: Array<any> = [strategy.dbId];
        that.deleteStrategy({ dbId: ids }).then(() => {
          this.$eventBus.$emit('deleteStrategy', ids);
          if (this.showSnackbarNotifications) {
            if (this.$currentUser.isTeacher) {
              this.$snotify.success(this.$t('statusMsg54') as string);
            } else {
              this.$snotify.success(this.$t('statusMsg54b') as string);
            }
          }
          that.initialize(false);
        });
      }
    });
  }

  deleteStrategies() {
    if (this.hasSelectedRows) {
      const that = this;
      this.$refs.strategiesConfirm.confirm({
        title: this.$t('strategyLabel'),
        text: this.$t('deleteStrategiesLabel'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.localListLoading = true;
          const ids: Array<any> = [];
          this.selectedRows.forEach((s: any) => {
            ids.push(s.dbId);
          });
          this.selectedRows = [];
          that.deleteStrategy({ dbId: ids }).then(() => {
            this.$eventBus.$emit('deleteStrategy', ids);
            if (this.showSnackbarNotifications) {
              if (this.$currentUser.isTeacher) {
                this.$snotify.success(this.$t('statusMsg54') as string);
              } else {
                this.$snotify.success(this.$t('statusMsg54b') as string);
              }
            }
            that.initialize(false);
          });
        }
      });
    }
  }

  saveStrategyId(item: any) {
    const strategies = ld.cloneDeep(this.localStrategies);
    const strategy = strategies.find(s => s.dbId === item.dbId);
    this.localListLoading = true;
    this.updateStrategy({ dbId: strategy.dbId, strategyId: strategy.strategyId, strategyDesc: strategy.strategyDesc, strategyTitle: strategy.strategyTitle, strategyGroupType: strategy.strategyGroupType }).then(() => {
      this.localListLoading = false;
      if (this.showSnackbarNotifications) {
        if (this.$currentUser.isTeacher) {
          this.$snotify.success(this.$t('statusMsg55') as string);
        } else {
          this.$snotify.success(this.$t('statusMsg55b') as string);
        }
      }
    });
    item.editStrategyId = false;
    this.$nextTick();
  }

  saveStrategyTitle(item: any) {
    const strategies = ld.cloneDeep(this.localStrategies);
    const strategy = strategies.find(s => s.dbId === item.dbId);
    this.localListLoading = true;
    this.updateStrategy({ dbId: strategy.dbId, strategyId: strategy.strategyId, strategyDesc: strategy.strategyDesc, strategyTitle: strategy.strategyTitle, strategyGroupType: strategy.strategyGroupType }).then(() => {
      this.localListLoading = false;
      if (this.showSnackbarNotifications) {
        if (this.$currentUser.isTeacher) {
          this.$snotify.success(this.$t('statusMsg55') as string);
        } else {
          this.$snotify.success(this.$t('statusMsg55b') as string);
        }
      }
    });
    item.editStrategyTitle = false;
    this.$nextTick();
  }

  doInit(reload: boolean) {
    this.localIsEditingStrategies = false;
    if (reload) {
      this.localListLoading = true;
      this.initialize(false);
    }
  }

  onUserModeChanged() {
    this.doInit(true);
  }

  created() {
    this.initialize(true);
    this.$nextTick(() => {
      this.$eventBus.$on('closeStrategyEditor', (reload: boolean) => {
        this.doInit(reload);
      });

      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);

      this.$eventBus.$emit('openDefaultSubPage');
    })
  }

  destroyed() {
    this.$eventBus.$off('closeStrategyEditor');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
  }

  updated() {
    WebUtils.initMathJax();
  }
}
